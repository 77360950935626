import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Productos y servicios',
    meta: {
      title: 'Home'
    },
    component: resolve => {
      require(['@/views/home/index.vue'], resolve)
    }
  },
  {
    path: '/about_us',
    name: 'Sobre Nosotros',
    meta: {
      title: 'AboutUs'
    },
    component: resolve => {
      require(['@/views/aboutUs/index.vue'], resolve)
    }
  },
  {
    path: '/solution',
    name: 'Soluciones',
    meta: {
      title: 'Solution'
    },
    component: resolve => {
      require(['@/views/solution/index.vue'], resolve)
    }
  },
  {
    path: '/market',
    name: 'Mercados',
    meta: {
      title: 'Market'
    },
    component: resolve => {
      require(['@/views/market/index.vue'], resolve)
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes: routes
})

export default router
